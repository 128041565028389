import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import type { HeaderNavItem } from "@circle-react/components/SettingsApp/Navigation/hooks/useFetchHeaderNavItems";
import { useNavigationBar } from "../useNavigationBar";
import type { NavItem } from "../utils/transformHeaderNavItemsToNavItems";
import { transformHeaderNavItemsToNavItems } from "../utils/transformHeaderNavItemsToNavItems";
import {
  getPathFromUrl,
  isPathActive,
  isUrlFromCurrentDomain,
} from "../utils/urlHelpers";

export interface UseTransformedNavItemsParams {
  headerNavItems: HeaderNavItem[];
  homeLinkTo: string;
  isCommunityAdmin: boolean;
  isDisabled: boolean;
  isHomeLinkActive: boolean;
}

export interface NavigationLink {
  isActive: boolean;
  isDisabled: boolean;
  isPresent: boolean;
  kind: NavItem["kind"];
  label: string;
  linkTo: string;
  openInNewWindow: boolean;
  visibility: NavItem["visibility"];
}

const isEnabled = (item: NavItem) => item.visibility === "enabled";
const isDisabled = (item: NavItem) => item.visibility === "disabled";
const isAdminOnly = (item: NavItem) => item.visibility === "admin_only";
const isItemOfKind = (kind: string) => (item: NavItem) => item.kind === kind;

const useAdminFilterLogic = () => {
  const { shouldShowLeaderboardLink } = useNavigationBar();

  return (item: NavItem) =>
    isItemOfKind("leaderboard")(item)
      ? shouldShowLeaderboardLink && !isDisabled(item)
      : !isDisabled(item);
};

const useMemberFilterLogic = () => {
  const {
    shouldShowMembersLink,
    shouldShowAllEventsLink,
    shouldShowCoursesLink,
    shouldShowLeaderboardLink,
  } = useNavigationBar();

  return (item: NavItem) => {
    if (isItemOfKind("members")(item)) {
      return shouldShowMembersLink && isEnabled(item);
    }
    if (isItemOfKind("events")(item)) {
      return shouldShowAllEventsLink && isEnabled(item);
    }
    if (isItemOfKind("courses")(item)) {
      return shouldShowCoursesLink && isEnabled(item);
    }
    if (isItemOfKind("leaderboard")(item)) {
      return shouldShowLeaderboardLink && isEnabled(item);
    }
    return !isDisabled(item) && !isAdminOnly(item);
  };
};

export const useNavItemsToLinks = ({
  headerNavItems,
  isDisabled,
  homeLinkTo,
  isHomeLinkActive: defaultIsHomeLinkActive,
  isCommunityAdmin,
}: UseTransformedNavItemsParams) => {
  const adminFilterLogic = useAdminFilterLogic();
  const memberFilterLogic = useMemberFilterLogic();
  const { pathname: currentPathname } = useLocation();

  return useMemo(() => {
    const navItems = transformHeaderNavItemsToNavItems(
      headerNavItems,
      homeLinkTo,
    );
    const filterLogic = isCommunityAdmin ? adminFilterLogic : memberFilterLogic;
    const filteredNavItems = navItems.filter(filterLogic);

    // First pass: Check if any custom link should be active
    const isAnyCustomLinkActive = filteredNavItems.some(item => {
      if (item.kind !== "custom") return false;

      const isInternalLink = isUrlFromCurrentDomain(item.url);
      if (!isInternalLink) return false;

      const pathFromUrl = getPathFromUrl(item.url);
      return isPathActive(currentPathname, pathFromUrl);
    });

    // If any custom link is active, the home link should not be active
    const isHomeLinkActive = isAnyCustomLinkActive
      ? false
      : defaultIsHomeLinkActive;

    // Second pass: Map items to links with correct active state
    return filteredNavItems.map(item => {
      // For custom links, check if they point to our application and if the path is active
      const isCustomLinkInternal =
        item.kind === "custom" && isUrlFromCurrentDomain(item.url);
      const pathFromUrl = isCustomLinkInternal ? getPathFromUrl(item.url) : "";
      const isCustomLinkActive =
        isCustomLinkInternal && isPathActive(currentPathname, pathFromUrl);

      return {
        linkTo: item.url,
        label: item.name,
        isPresent: true,
        isDisabled: isDisabled,
        isActive: isItemOfKind("home")(item)
          ? isHomeLinkActive
          : isCustomLinkActive,
        kind: item.kind,
        visibility: item.visibility,
        openInNewWindow: item.open_in_new_window,
      };
    });
  }, [
    headerNavItems,
    isDisabled,
    homeLinkTo,
    defaultIsHomeLinkActive,
    isCommunityAdmin,
    adminFilterLogic,
    memberFilterLogic,
    currentPathname,
  ]);
};
