/**
 * Checks if a full URL points to the current application
 */
export const isUrlFromCurrentDomain = (url: string): boolean => {
  try {
    // Only process URLs with protocol
    if (url.includes("://")) {
      return new URL(url).hostname === window.location.hostname;
    }
    return false;
  } catch (e) {
    return false;
  }
};

/**
 * Extracts path from a full URL
 */
export const getPathFromUrl = (url: string): string => {
  try {
    return new URL(url).pathname;
  } catch (e) {
    return "";
  }
};

/**
 * Determines if a path matches the current location
 */
export const isPathActive = (pathname: string, urlPath: string): boolean =>
  pathname === urlPath || (urlPath !== "/" && pathname.startsWith(urlPath));
